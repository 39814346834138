import React, { useRef, useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import {
  LayoutComposer,
  LayoutSingleColumn,
  NamedRedirect,
  Page,
  VideoPlayer,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import simpbook1 from '../../assets/simpbook1.png';
import simpbook2 from '../../assets/simpbook2.png';
import simpbook4 from '../../assets/simpbook4.png';
import simpbook3 from '../../assets/simpbook3.png';
import landingPageReviwImgstars from '../../assets/landingPageReviwImgstars.png';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';

import FooterContainer from '../FooterContainer/FooterContainer.js';
import { validProps } from '../PageBuilder/Field';
import { ReactComponent as SearchIcon } from './icons/Search.svg';
import { ReactComponent as DogIcon } from './icons/dog.svg';
import StarIcon from './icons/staricon.png';
import evevaleImg from './icons/joinedImg.png';
import { ReactComponent as CatIcon } from './icons/cat.svg';
import { ReactComponent as HorseIcon } from './icons/horse.svg';
import { ReactComponent as RabbitIcon } from './icons/rabbit.svg';
import { ReactComponent as RodentsIcon } from './icons/rodents.svg';
import PetStarAcademyGraphic from '../../assets/PetStarAcademyGraphic.png';
import { ReactComponent as TortoiseIcon } from './icons/tortoise.svg';
import { ReactComponent as BirdIcon } from './icons/birds.svg';
import { ReactComponent as PigIcon } from './icons/pigs.svg';
import { ReactComponent as CattlesIcon } from './icons/cattles.svg';
import { ReactComponent as GoatIcon } from './icons/goats.svg';
import { ReactComponent as LandingPageLi } from './icons/landingPageLi.svg';
import { ReactComponent as PrevButton } from './icons/prevArrow.svg';
import { ReactComponent as NextButton } from './icons/nextArrow.svg';
import bannerImage from './icons/banner_image.png';
import netflix from './icons/asSeenOn/netflix.png';
import petstars from './icons/asSeenOn/petstars.png';
import people from './icons/asSeenOn/people.png';
import gdmrn from './icons/asSeenOn/gdmrn.png';
import bsnins from './icons/asSeenOn/bsnins.png';
import partyDog from './icons/dog-with-party-hat.png';
import b7 from './icons/brands/7.png';
import b8 from './icons/brands/8.png';
import b9 from './icons/brands/9.png';
import b10 from './icons/brands/10.png';
import b11 from './icons/brands/11.png';
import b12 from './icons/brands/12.png';
import b13 from './icons/brands/13.png';
import b14 from './icons/brands/14.png';
import b15 from './icons/brands/15.png';
import b16 from './icons/brands/16.png';
import b17 from './icons/brands/17.png';
import b18 from './icons/brands/18.png';
import b19 from './icons/brands/19.png';
import b20 from './icons/brands/20.png';
import b21 from './icons/brands/21.png';
import b22 from './icons/brands/22.png';
import b23 from './icons/brands/23.png';
import b24 from './icons/brands/24.png';
import b25 from './icons/brands/25.png';
import b26 from './icons/brands/26.png';
import b27 from './icons/brands/27.png';
import b28 from './icons/brands/28.png';
import b29 from './icons/brands/29.png';
import b30 from './icons/brands/30.png';
import b32 from './icons/brands/32.png';
import b34 from './icons/brands/34.png';
import b35 from './icons/brands/35.png';
import b36 from './icons/brands/36.png';
import b37 from './icons/brands/37.png';
import clapperImg from './icons/clapperImg.png';
import parotImg from './icons/parotImg.png';
import dogImg from './icons/dogImg.png';
import rebecca from './icons/Testimonial_rebecca_1.png';
import justin from './icons/Testimonial_justin4.png';
import rider from './icons/rider.png';
import purnia from './icons/Testimonial3_Purina.png';
import furbo from './icons/Testimonial_furbo2.png';
import animalProf from './icons/popularServices/animalProf.png';
import animalAct from './icons/popularServices/animalAct.png';
import entAl from './icons/popularServices/entAl.png';
import petContCr from './icons/popularServices/petContCr.png';
import petInf from './icons/popularServices/petInf.png';

import LandingGuideImg1 from './icons/LandingGuideImg1.png';
import LandingGuideImg2 from './icons/LandingGuideImg2.png';
import LandingGuideImg3 from './icons/LandingGuideImg3.png';

import css from './LandingPage.module.css';
import videoUrl from './video.mp4';

import Slider from 'react-slick';
import { withViewport } from '../../util/uiHelpers.js';
import './slick.css';
import { createResourceLocatorString } from '../../util/routes.js';
import { useRouteConfiguration } from '../../context/routeConfigurationContext.js';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const CUSTOMER = 'customer';
const PROVIDER = 'provider';

const gblVenuWrapper = 'gblVenuWrapper';
const DESKTOP_MAX_CONTAINER_WIDTH = 1400;
const TAB_MAX_CONTAINER_WIDTH = 1024;
const MOBILE_MAX_CONTAINER_WIDTH = 576;
const CONTAINER_DESKTOP_OFFSET_WIDTH = 0;
const LISTING_CARD_WIDTH = 384;
const CONTAINER_TAB_OFFSET_WIDTH = 48;
const CONTAINER_MOBILE_OFFSET_WIDTH = 49;

// Panel width relative to the viewport
const panelMediumWidth = 50;
const panelLargeWidth = 62.5;
const cardRenderSizes = [
  '(max-width: 767px) 100vw',
  `(max-width: 1023px) ${panelMediumWidth}vw`,
  `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
  `${panelLargeWidth / 3}vw`,
].join(', ');

// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const reviews = [
  {
    id: 1,
    photo: rebecca,
    quote:
      '"I’m so glad to be a part of the Pets on Q team! Colleen and Melissa are wonderful. Over the past few years, they’ve made countless campaigns and deals a reality. Coby would not be where he is today without them!"',
    author: 'Rebecca, @cobythecat',
  },
  {
    id: 2,
    photo: purnia,
    quote:
      '"As we wrap up the LiveClear program, I wanted to thank Pets on Q for being such a great partner as always! Clients are thrilled with how everything turned out."',
    author: 'Purina, Marketing Manager',
  },
  {
    id: 3,
    photo: furbo,
    quote:
      '"WE LOVE the content and the campaign was super smooth. Thank you guys for another great campaign!"',
    author: 'Furbo, Social Media Manager',
  },
  {
    id: 4,
    photo: justin,
    quote:
      '"Doggy talent was fantastic – they were all great but especially the ones we had on day 1. All of us, including the whole crew, were blown away at how well trained they were and the range of shots we were able to capture. Thanks so much for working with us on it."',
    author: 'Justin, Producer',
  },
];

const asSeenOnImages = [
  { alt: 'Netflix', img: netflix },
  { alt: 'Pets-stars', img: petstars },
  { alt: 'Business-Insider', img: bsnins },
  { alt: 'People', img: people },
  { alt: 'Good-Morning-America', img: gdmrn },
];

const brandImgs = [
  b7,
  b8,
  b9,
  b10,
  b11,
  b12,
  b13,
  b14,
  b15,
  b16,
  b17,
  b18,
  b19,
  b20,
  b21,
  b22,
  b23,
  b24,
  b25,
  b26,
  b27,
  b28,
  b29,
  b30,
  b32,
  b34,
  b35,
  b36,
  b37,
];
const guidesData = [
  {
    img: LandingGuideImg1,
    // text: <FormattedMessage id="LandingPageGuide.Option1" />,
    text: <FormattedMessage id="LandingPageGuide.Option3" />,
    onClick: () => {
      window.open(
        'https://www.petsonq.com/blog/unlock-the-extraordinary-a-buyers-handbook-for-booking-a-diverse-range-of-animal-talent-on-the-animal-list/',
        '_blank'
      );
    },
  },
  {
    img: LandingGuideImg2,
    text: <FormattedMessage id="LandingPageGuide.Option2" />,
    onClick: () => {
      window.open(
        'https://www.petsonq.com/blog/animal-talent-guide-working-in-the-animal-list/',
        '_blank'
      );
    },
  },
  {
    img: LandingGuideImg3,
    text: <FormattedMessage id="LandingPageGuide.Option1" />,
    onClick: () => {
      window.open(
        'https://www.petsonq.com/blog/understanding-petfolios-vs-listings-setting-yourself-apart/',
        '_blank'
      );
    },
  },
];
const popularServiceImages = [
  {
    alt: 'Animal-Professional',
    img: animalProf,
    search: {
      pub_listingCategory: 'listing',
      pub_category: 'animalProfessional',
    },
  },
  { alt: 'Pet-Influencer', img: petInf, search: {} },
  {
    alt: 'Animal-Actor',
    img: animalAct,
    search: {
      pub_listingCategory: 'listing',
      pub_category: 'entertainmentAnimals',
      pub_subCategory: 'has_any:certifiedAnimalActors',
    },
  },
  {
    alt: 'Entertainment-Animals',
    img: entAl,
    search: {
      pub_listingCategory: 'listing',
      pub_category: 'entertainmentAnimals',
    },
  },
  {
    alt: 'Pet-Content-Creator',
    img: petContCr,
    search: {
      pub_listingCategory: 'listing',
      pub_category: 'animalContentCreator',
    },
  },
];

const howALWorks = [
  {
    icon: <img src={simpbook1} />,
    heading: 'Easily Search',
    desc: 'Post a casting call or send direct offers to attract potential animal talent.',
  },
  {
    icon: <img src={simpbook2} />,
    heading: 'Book',
    desc: 'Book your chosen talent and message them to finalize details.',
  },
  {
    icon: <img src={simpbook3} />,
    heading: 'Enjoy',
    desc: 'Enjoy the talents of your selected animal at your event.',
  },
  {
    icon: <img src={simpbook4} />,
    heading: 'Review',
    desc: 'Leave a review after the job is done; payment will be sent directly to the talent.',
  },
];

export const LandingPageComponent = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    schemaType,
    viewport,
    history,
    currentUser,
    isAuthenticated,
    ...pageProps
  } = props;
  var brandSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    // slidesToShow: 3,
    slidesToShow: viewport.width < 576 ? 3 : viewport.width < 1920 ? 5 : 7,
    slidesToScroll: viewport.width < 1023 ? 3 : 5,
    arrows: true,
    prevArrow: <PrevButton type="prev" />,
    nextArrow: <NextButton type="next" />,
  };
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType);
  const containerRef = useRef(null);
  const [keyword, setKeyword] = useState('');

  const route = useRouteConfiguration();

  const { userType, providerCategory } = currentUser?.attributes?.profile?.publicData || {};
  const currentUserLoaded = !!currentUser?.id;

  const isCustomer = userType === CUSTOMER;
  const isProvider = userType === PROVIDER;
  if (isAuthenticated && currentUserLoaded && !userType) {
    return <NamedRedirect name="OnboardRootPage" />;
  } else if (isAuthenticated && currentUserLoaded && isProvider && !providerCategory) {
    return <NamedRedirect name="OnboardRootPage" />;
  }
  useEffect(() => {
    if (containerRef.current !== null) {
      const firstChild = containerRef.current.firstElementChild;
      const { width } = viewport;
      if (width >= MOBILE_MAX_CONTAINER_WIDTH && width < TAB_MAX_CONTAINER_WIDTH) {
        // console.log(1);
        firstChild.style.maxWidth = `${width - CONTAINER_TAB_OFFSET_WIDTH}px`;
      } else if (width >= TAB_MAX_CONTAINER_WIDTH && width < DESKTOP_MAX_CONTAINER_WIDTH) {
        // console.log(2, width, CONTAINER_DESKTOP_OFFSET_WIDTH);

        // firstChild.style.maxWidth = `${width - CONTAINER_DESKTOP_OFFSET_WIDTH}px`;
        firstChild.style.maxWidth = `${width - 220}px`;
      } else if (width >= DESKTOP_MAX_CONTAINER_WIDTH) {
        // console.log(3);

        firstChild.style.maxWidth = `${DESKTOP_MAX_CONTAINER_WIDTH -
          CONTAINER_DESKTOP_OFFSET_WIDTH}px`;
      } else {
        // console.log(4);

        firstChild.style.maxWidth = `${width - CONTAINER_MOBILE_OFFSET_WIDTH}px`;
      }
      const totalWidth =
        firstChild.children.length > 1
          ? firstChild.children[1].offsetWidth
          : firstChild.children[0].offsetWidth;

      // const newItemCount = parseFloat((totalWidth / LISTING_CARD_WIDTH).toFixed(1));
      // if (prevState.itemCount !== newItemCount) this.setState({ itemCount: newItemCount });
    }
  }, [viewport]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevButton type="prev" />,
    nextArrow: <NextButton type="next" />,
  };
  const searchContainerMaybe = (clname = null) => (
    <div className={classNames(css.searchContainer, clname)}>
      <button
        type="button"
        onClick={() => {
          history.push(
            createResourceLocatorString('SearchPage', route, {}, { pub_listingCategory: 'listing' })
          );
        }}
      >
        Search Animal Talent
      </button>
      <button
        type="button"
        onClick={e => {
          history.push(createResourceLocatorString('NewCastingCallPage', route, {}));
        }}
      >
        Create a Casting Call
      </button>
    </div>
  );
  const mainContent = (
    <div className={css.mainContainer}>
      <div className={css.heroSection}>
        <div className={css.leftSection}>
          <div className={css.title}>
            <p>Simplify Your Search</p>
            <p>
              for the <i>Perfect</i> Pet Star
            </p>
          </div>
          <div className={css.subTitle}>
            Discover a wide range of animal talent and effortlessly hire the perfect animal talent
            for your production, event, marketing campaign, and more.
          </div>
          {searchContainerMaybe()}
          <div className={css.postListingLink}>
            Want to list your animal talent for hire?{' '}
            <span
              onClick={() => {
                history.push(createResourceLocatorString('SellerLandingPage', route, {}, {}));
              }}
            >
              Click here.
            </span>
          </div>
        </div>
        <div className={css.rightSection}>
          <img src={bannerImage} className={css.heroIcon} />
          <span className={`${css.headTags} ${css.headtag1}`}>
            <IoIosCheckmarkCircleOutline />
            Simple booking
          </span>
          <span className={`${css.headTags} ${css.headtag2}`}>
            <IoIosCheckmarkCircleOutline />
            10 years in business
          </span>
          <span className={`${css.headTags} ${css.headtag3}`}>
            <IoIosCheckmarkCircleOutline />
            Thousands of booked animals
          </span>
          <span className={`${css.headTags} ${css.headtag4}`}>
            <IoIosCheckmarkCircleOutline />
            Access to Certified Animals
          </span>
        </div>
      </div>
      <div className={`${css.sections} ${css.asSeenOnContainer}`}>
        <span>As Seen On:</span>
        <div className={css.asSeenOnImgDiv}>
          {asSeenOnImages.map(as => (
            <img src={as.img} alt={as.alt} />
          ))}
        </div>
      </div>
      <div className={`${css.sections} ${css.popularSearchContainer}`}>
        <div className={css.sectionTitle}>Popular Services</div>
        <div className={css.popularMainSection}>
          {popularServiceImages.map(ps => (
            <div
              onClick={e => {
                history.push(
                  createResourceLocatorString('SearchPage', route, {}, { ...ps.search })
                );
              }}
              className={css.cardContainer}
            >
              <img src={ps.img} alt={ps.alt} className={css.cardIcon} />
            </div>
          ))}
        </div>
      </div>

      <div className={`${css.sections} ${css.exploreContainer}`}>
        <div className={`${css.sectionTitle} ${css.sectitwithanc}`}>
          <span>Explore our Pet Stars</span>
          <a href="https://www.petsonq.com/our-work/" target="_blank">
            View Our Work
          </a>
        </div>
        <div className={css.exploreIconContainer}>
          <div className={css.firstRow}>
            <div
              className={css.iconGrp}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    route,
                    {},
                    { pub_typeOfAnimal: 'has_any:dog' }
                  )
                );
              }}
            >
              <DogIcon />
              <div className={css.iconLabel}>Dogs</div>
            </div>
            <div
              className={css.iconGrp}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    route,
                    {},
                    { pub_typeOfAnimal: 'has_any:cat' }
                  )
                );
              }}
            >
              <CatIcon />
              <div className={css.iconLabel}>Cats</div>
            </div>
            <div
              className={css.iconGrp}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    route,
                    {},
                    { pub_animal: 'farmAnimals', pub_typeOfAnimal: 'has_any:horseDonkey' }
                  )
                );
              }}
            >
              <HorseIcon />
              <div className={css.iconLabel}>Horses</div>
            </div>
            <div
              className={css.iconGrp}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    route,
                    {},
                    // { pub_typeOfAnimal: 'has_any:rabbit' }
                    { pub_animal: 'farmAnimals', pub_typeOfAnimal: 'has_any:bunnyRabbit' }
                  )
                );
              }}
            >
              <RabbitIcon />
              <div className={css.iconLabel}>Rabbits</div>
            </div>
            <div
              className={css.iconGrp}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    route,
                    {},
                    // { pub_typeOfAnimal: 'has_any:rodents' }
                    { pub_animal: 'rodents' }
                  )
                );
              }}
            >
              <RodentsIcon />
              <div className={css.iconLabel}>Rodents</div>
            </div>
            <div
              className={css.iconGrp}
              onClick={() => {
                history.push(
                  createResourceLocatorString('SearchPage', route, {}, { pub_animal: 'reptiles' })
                );
              }}
            >
              <TortoiseIcon />
              <div className={css.iconLabel}>Reptiles</div>
            </div>
            <div
              className={css.iconGrp}
              onClick={() => {
                history.push(
                  createResourceLocatorString('SearchPage', route, {}, { pub_animal: 'birds' })
                );
              }}
            >
              <BirdIcon />
              <div className={css.iconLabel}>Birds</div>
            </div>
            <div
              className={css.iconGrp}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    route,
                    {},
                    // { pub_typeOfAnimal: 'has_any:pig' }
                    { pub_animal: 'farmAnimals', pub_typeOfAnimal: 'has_any:pigs' }
                  )
                );
              }}
            >
              <PigIcon />
              <div className={css.iconLabel}>Pigs</div>
            </div>
            <div
              className={css.iconGrp}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    route,
                    {},
                    { pub_animal: 'farmAnimals', pub_typeOfAnimal: 'has_any:cattle' }
                  )
                );
              }}
            >
              <CattlesIcon />
              <div className={css.iconLabel}>Cattle</div>
            </div>
            <div
              className={css.iconGrp}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    route,
                    {},
                    { pub_animal: 'farmAnimals', pub_typeOfAnimal: 'has_any:goatsSheep' }
                  )
                );
              }}
            >
              <GoatIcon />
              <div className={css.iconLabel}>Goats</div>
            </div>
          </div>

          {/* <div className={css.secondRow}>
            
          </div> */}
        </div>
      </div>
      <div className={`${css.sections} ${css.guideContainer} ${css.simpBookCont}`}>
        <div className={css.descLeft}>
          <h2>Simplified Booking of Animal Talent</h2>
          <div className={css.listSection}>
            {howALWorks.map((h, idx) => (
              <div key={idx} className={css.pointContainer}>
                {h.icon}
                <div className={css.lbl}>{`${idx + 1}. ${h.heading}`}</div>
                <p>{h.desc}</p>
              </div>
            ))}
          </div>
          {searchContainerMaybe(`${css.thirsearchcon} ${css.seaconSearchCOnt}`)}
        </div>
        {/* <div className={css.guideLeft}>
          <p className={css.elevp}>Simplified Booking of Animal Talent</p>
          <ol>
            <li className={classNames(css.benfdv, css.simpbook)}>
              <p>Choose the animal talent you need</p>
              <p>
                Effortlessly discover your perfect animal talent from our categorized list. Explore
                details, reviews, or simply place a casting call for direct applications to meet
                your unique needs.
              </p>
            </li>
            <li className={classNames(css.benfdv, css.simpbook)}>
              <p>Customizing your animal talent experience</p>
              <p>
                Select personalized add-ons and inclusions to ensure your hiring engagement is a
                success. Our vast array of talent to choose from plus our streamlined system
                safeguard, and enhance your booking experience.
              </p>
            </li>
            <li className={classNames(css.benfdv, css.simpbook)}>
              <p>Swiftly finalize your payment to secure your booking</p>
              <p>
                Expedite your booking by finalizing payment upfront. Funds are held until the job's
                completion, ensuring trust and satisfaction for both parties.
              </p>
            </li>
            <li className={classNames(css.benfdv, css.simpbook)}>
              <p>Easy communication</p>
              <p>
                You can chat with the animal talent provider you've booked to ensure a seamless
                experience for both parties.
              </p>
            </li>
          </ol>
        </div>
        <img alt="PetStarAcademyGraphic" src={laptopImg} />
        <img alt="PetStarAcademyGraphic" src={catImg} className={css.catImg} /> */}
      </div>
      <div className={`${css.sections} ${css.testimonialContainer}`} ref={containerRef}>
        <Slider {...settings} className={css.review}>
          {reviews.map(review => (
            <div key={review.id} className={`${css.pltreviews} ${css.quoteSection}`}>
              <div className={css.reviewImg}>
                {/* <img src={landingPageReviwImgstars} className={css.landingPageReviwImgstars} /> */}
                <img alt="" src={review.photo} className={css.revImg} />
              </div>
              {/* <VideoPlayer
                playerClass={css.player}
                videoUrl={videoUrl}
                thumbnailUrl="https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Big_Buck_Bunny_-_forest.jpg/800px-Big_Buck_Bunny_-_forest.jpg"
              /> */}
              <div className={css.quoteTxt}>
                <blockquote>
                  <p>{review.quote}</p>
                  <footer>- {review.author}</footer>
                </blockquote>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className={`${css.sections} ${css.popularSearchContainer}`}>
        <div className={css.popularMainSection}>
          <Slider {...brandSettings} className={css.slider}>
            {brandImgs.map((logo, idx) => (
              <div key={idx} className={css.brandquoteSection}>
                <img className={css.quoteImg} alt="" src={logo} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className={`${css.sections} ${css.actorLevelsSec}`}>
        <div className={css.guideContainer}>
          <div className={classNames(css.guideLeft, css.dummyGuideLeft)}>
            <p className={css.elevp}>Elevating Entertainment Standards with Animal Actors</p>
            <p className={css.elevpdesc}>
              We set the gold standard for animal actor approvals, focusing on well-being and
              professionalism. Our in-person certification, conducted by industry experts, ensures
              high standards, reliability and accountability:
            </p>
            <div className={`${css.benfdv} ${css.cenf}`}>
              <img src={StarIcon} />
              <div>
                <p>Level One: Temperament Approved</p>
                <p>Basic health, temperament, and obedience for simple tasks.</p>
              </div>
            </div>
            <div className={`${css.benfdv} ${css.cenf}`}>
              <img src={StarIcon} />
              <div>
                <p>Level Two: Animal Actors</p>
                <p>Proficient in basic commands for supporting roles.</p>
              </div>
            </div>
            <div className={`${css.benfdv} ${css.cenf}`}>
              <img src={StarIcon} />
              <div>
                <p>Level Three: Advanced Animal Actors</p>
                <p>Skilled in complex tasks and versatile roles.</p>
              </div>
            </div>
            <div className={`${css.benfdv} ${css.cenf}`}>
              <img src={StarIcon} />
              <div>
                <p>Level Four: Elite Animal Actors</p>
                <p>Most experienced performers for demanding roles.</p>
              </div>
            </div>
            {searchContainerMaybe(css.seaconSearchCOnt)}
          </div>
          <img alt="PetStarAcademyGraphic" src={evevaleImg} className={css.elevImg} />
        </div>
      </div>
      <div className={`${css.sections} ${css.listingContainer}`}>
        <div className={css.sectionTitle}>Guides to help you get started</div>
        <div className={css.popularMainSection}>
          {guidesData.map(data => (
            <div className={css.listingCardContainer} onClick={data.onClick}>
              <img src={data.img} className={css.cardIcon} />
              <div className={css.cardLabel}>{data.text}</div>
            </div>
          ))}
          {/* <div
            className={css.listingCardContainer}
            onClick={() => {
              window.open(
                'https://www.petsonq.com/blog/understanding-petfolios-vs-listings-setting-yourself-apart/',
                '_blank'
              );
            }}
          >
            <img src={LandingGuideImg1} className={css.cardIcon} />
            <div className={css.cardLabel}>
              <FormattedMessage id="LandingPageGuide.Option1" />
            </div>
          </div>

          <div
            className={css.listingCardContainer}
            onClick={() => {
              window.open(
                'https://www.petsonq.com/blog/animal-talent-guide-working-in-the-animal-list/',
                '_blank'
              );
            }}
          >
            <img src={LandingGuideImg2} className={css.cardIcon} />
            <div className={css.cardLabel}>
              {' '}
              <FormattedMessage id="LandingPageGuide.Option2" />
            </div>
          </div>

          <div
            className={css.listingCardContainer}
            onClick={() => {
              window.open(
                'https://www.petsonq.com/blog/unlock-the-extraordinary-a-buyers-handbook-for-booking-a-diverse-range-of-animal-talent-on-the-animal-list/',
                '_blank'
              );
            }}
          >
            <img src={LandingGuideImg3} className={css.cardIcon} />
            <div className={css.cardLabel}>
              <FormattedMessage id="LandingPageGuide.Option3" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );

  const layoutAreas = `
    topbar
    main
    footer
  `;
  return (
    // <StaticPage {...pageMetaProps} {...pageProps}>
    //   <LayoutComposer areas={layoutAreas} className={css.layout}>
    //     {props => {
    //       const { Topbar, Main, Footer } = props;
    //       return (
    //         <>
    //           <Topbar as="header" className={css.topbar}>
    //             <TopbarContainer />
    //           </Topbar>
    //           <Main as="main" className={css.main}>
    //             {mainContent}
    //           </Main>
    //           <FooterContainer />
    //         </>
    //       );
    //     }}
    //   </LayoutComposer>
    // </StaticPage>
    <Page>
      <TopbarContainer />
      {/* <LayoutSingleColumn>{mainContent}</LayoutSingleColumn> */}
      {mainContent}
      <FooterContainer />
    </Page>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const { currentUser } = state.user;
  return { pageAssetsData, inProgress, error, currentUser, isAuthenticated };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  withViewport
)(LandingPageComponent);

export default LandingPage;
